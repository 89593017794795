<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="info" v-if="paramsId">
        <div>
          <span class="mr-20">所属商户：{{form.storeName}}</span>
          <span class="mr-20">门店编号：{{form.shopId}}</span>
          <span class="mr-20">门店状态：{{form.statusName}}</span>
        </div>
      </div>
      <div slot="content">
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{span:8}"
          :wrapper-col="{span:14}">
          <a-card title="门店信息">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="门店名称" prop="shopName">
                  <a-input v-model="form.shopName" :maxLength="50" placeholder="请输入" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>

              <a-col :span="10">
                <!--<a-form-model-item label="所属商户" prop="storeId">-->
                  <!--<a-select v-model="form.storeId" allowClear placeholder="请选择" v-if="!isDetail">-->
                    <!--<a-select-option :value="item.code" v-for="item in storeList" :key="item.code">-->
                      <!--{{item.name}}-->
                    <!--</a-select-option>-->
                  <!--</a-select>-->
                  <!--<span v-else>{{form.storeName}}</span>-->
                <!--</a-form-model-item>-->
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="联系人" prop="linkman">
                  <a-input v-model="form.linkman" :maxLength="50" placeholder="请输入" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="联系电话" prop="mobile">
                  <a-input v-model="form.mobile" :maxLength="50" placeholder="请输入" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="地址" prop="address">
                  <a-input v-model="form.address" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }"placeholder="请输入" allowClear :maxLength="150" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-card>
          <a-card class="mt-20" title="账号信息" v-if="!paramsId">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="账号" prop="loginName">
                  <a-input v-model="form.loginName" :maxLength="50" allowClear></a-input>
                </a-form-model-item>
              </a-col>

              <a-col :span="10">
                <a-form-model-item label="姓名" prop="userName">
                  <a-input v-model="form.userName" :maxLength="50" allowClear></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="密码" prop="password">
                  <a-input v-model="form.password" type="password"  :maxLength="50"  allowClear autocomplete="off" />
                </a-form-model-item>
              </a-col>

              <a-col :span="10">
                <a-form-model-item label="确认密码" prop="password1">
                  <a-input v-model="form.password1" type="password"  :maxLength="50"  allowClear autocomplete="off" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-card>
        </a-form-model>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { shopInfo, shopSave, shopUpdate, storeListAll} from '@/api/tool';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'shopForm',
  mixins: [formMixin],
  components: {
  },
  props: {},
  data() {
    let validatePass = (rule, value, callback) => {
      if (this.form.password1 && value !== this.form.password1) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (this.form.password && value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    return {
      storeList: [],
      form: {
        shopName: '',
        storeId: null,
        linkman: '',
        mobile: '',
        address: '',
        loginName: '',
        userName: '',
        password: '',
        password1: '',
      },
      rules: {
        shopName: [
          {
            required: true,
            message: '请输入门店名称',
            trigger: 'blur',
          },
        ],
        storeId: [
          {
            required: true,
            message: '请选择所属商户',
          },
        ],
        linkman: [
          {
            required: true,
            message: '请输入联系人'
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入手机号'
          },
        ],
        email: [
          {
            trigger: 'blur',
            type: 'email',
            message: '请输入正确的邮箱'
          }
        ],
        loginName: [
          {
            required: true,
            message: '请输入账号'
          },
        ],
        userName: [
          {
            required: true,
            message: '请输入姓名'
          },
        ],
        password: [{ required: true, message: '请输入新密码', }, { validator: validatePass, trigger: 'blur' }],
        password1: [{ required: true, message: '请输入确认密码', }, { validator: validatePass2, trigger: 'blur' }],
      },
      storeList: []
    };
  },
  computed: {},
  watch: {},
  created() {
    if (!this.isDetail) {
      this.getStoreList();
    }
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取信息
    getData(id) {
      shopInfo(id).then((data) => {
        this.form = data;
      });
    },

    getStoreList() {
      storeListAll().then((data) => {
        this.storeList = data;
      });
    },
    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = this.form;
          if (params.password1) {
            delete  params.password1;
          }
          const finalAction = this.paramsId ? shopUpdate : shopSave;
          finalAction(params).then(() => {
            this.$message.success('操作成功');
            this.handleBack();
          })
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
